import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert'
import CalibrationTypeLkp from '../../Component/Calibration/CalibrationTypeLkp';
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer';
import UnitOfMeasurmentLkp from '../../Component/Calibration/UnitOfMeasurmentLkp';
import t from '../../Lang/Index'
import ReportTemplateLkp from '../../Component/Calibration/ReportTemplateLkp';
import CalibrationProcedureLkp from '../../Component/Calibration/CalibrationProcedureLkp';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';
import moment from "moment";

// import CscDvManufacurerLkp from '../../Component/Calibration/CscDvManufacurerLkp';
// import CscDvValveTypeLkp from '../../Component/Calibration/CscDvValveTypeLkp';
// import CscDvBodyMaterialLkp from '../../Component/Calibration/CscDvBodyMaterialLkp';
// import CscDvDesingLkp from '../../Component/Calibration/CscDvDesingLkp';
// import CscDvTestMediumLkp from '../../Component/Calibration/CscDvTestMediumLkp';

const EquipmentCscDv_Add = (props) => {

    const PAGE_TITLE = "New " + t.cscDvUuc;
    const DEFUALT_CONTROLLER = "EquipmentCscDv";

    const [id, setId] = React.useState(props.match.params.id);
    const isEdit = props.match.params.id > 0;
    
  
    const [customerRef, setCustomerRef] = React.useState(-1);

    const [containerNo, setContainerNo] = React.useState('');
    const [containerType, setContainerType] = React.useState('');
    const [manufacturerName, setManufacturerName] = React.useState('');
    const [manufacturerNo, setManufacturerNo] = React.useState('');
    const [approvalNo, setApprovalNo] = React.useState('');
    const [yearBuilt, setYearBuilt] = React.useState('');
    const [cscSafetyApprovalNo, setCscSafetyApprovalNo] = React.useState('');
    const [classificationSociety, setClassificationSociety] = React.useState('');

    const [messages, setMessages] = React.useState([]);
    const [saveEnabled, setSaveEnabled] = React.useState(true);
    const [isGoingToAddNewReportDirectly, setIsGoingToAddNewReportDirectly] = React.useState(false)
    const [isRedirectToEquipment, setIsRedirectToEquipment] = React.useState(false)

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);


    const onCustomerRefChange = (e) => setCustomerRef(e.target.value);
   
    // Individual change handlers
    const onContainerNoChange = (e) => setContainerNo(e.target.value);
    const onContainerTypeChange = (e) => setContainerType(e.target.value);
    const onManufacturerNameChange = (e) => setManufacturerName(e.target.value);
    const onManufacturerNoChange = (e) => setManufacturerNo(e.target.value);
    const onApprovalNoChange = (e) => setApprovalNo(e.target.value);
    const onYearBuiltChange = (e) => setYearBuilt(e.target.value);
    const onCscSafetyApprovalNoChange = (e) => setCscSafetyApprovalNo(e.target.value);
    const onClassificationSocietyChange = (e) => setClassificationSociety(e.target.value);
    
    React.useEffect(() => {
        if (isEdit)
            fetchData();

    }, []);

    const fetchData = () => {
        debugger;
        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + "EquipmentCscDv/GetEntityRow/?id=" + id + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    const item = response.data;
                    debugger;
                    setCustomerRef(item.customerRef);
                    setContainerNo (item.containerNo);
                    setContainerType(item.containerType);
                    setManufacturerName(item.manufacturerName);
                    setManufacturerNo(item.manufacturerNo);
                    setApprovalNo(item.approvalNo);
                    setYearBuilt(item.yearBuilt);
                    setCscSafetyApprovalNo(item.cscSafetyApprovalNo);
                    setClassificationSociety(item.classificationSociety);
                    

                });
        });
    }
    
    const cancel = () => {
        setIsRedirectToEquipment(true);
    }

    const autoFill = () => {
        // Substances Suitable for Transport
        //setContainerNo('QPLU 0000275');
        setContainerType('1CC');
        setManufacturerName('FUYAN INDUSTRIAL PARK QINGDAO ECONOMIC & TECHNOLOGICAL DEVELOPMENT ZONE');
        setManufacturerNo('QP 19218484-QP 19218487');
        setApprovalNo('CN/CCS/QD130/19');
        setYearBuilt('8.2019');
        setCscSafetyApprovalNo('CN/CCS/QD130/19');
        setClassificationSociety('CCS');
    };

    const addAndGoToNewReport = () => {
        setIsGoingToAddNewReportDirectly(true);
        add();
    }

    const addEdit = () => {
        debugger;
        if (isEdit)
            edit();
        else
            add();
    }

    const add = () => {
        let messagesList = [];
        let self = this;

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL +  DEFUALT_CONTROLLER + "/add",
                {
                    CustomerRef: customerRef,
                    ContainerNo: containerNo,
                    ContainerType: containerType,
                    ManufacturerName: manufacturerName,
                    ManufacturerNo: manufacturerNo,
                    ApprovalNo: approvalNo,
                    YearBuilt: yearBuilt,
                    CscSafetyApprovalNo: cscSafetyApprovalNo,
                    ClassificationSociety: classificationSociety,
                
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let _id = response.data;
                    setId(_id)
                    //TO DO redirecto Calibration Report
                    setIsRedirectToEquipment(true);
                })
                .catch(error => {
                    debugger;
                    if (error.response && error.response.status == 400) {
                        for (var key in error.response.data) 
                            if (key.startsWith("message")) 
                                messagesList.push(error.response.data[key]);                                                   
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setMessages(messagesList);
                });
        });
    }

    const edit = () => {
        let messagesList = [];

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "EquipmentCscDv/edit",
                {
                    id: id,                    
                    CustomerRef: customerRef,
                    ContainerNo: containerNo,
                    ContainerType: containerType,
                    ManufacturerName: manufacturerName,
                    ManufacturerNo: manufacturerNo,
                    ApprovalNo: approvalNo,
                    YearBuilt: yearBuilt,
                    CscSafetyApprovalNo: cscSafetyApprovalNo,
                    ClassificationSociety: classificationSociety,
                
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let _id = response.data;
                    setId(_id)
                    
                    setIsRedirectToEquipment(true);
                })
                .catch(error => {
                    debugger;
                    if (error.response && error.response.status == 400) {
                        for (var key in error.response.data) 
                            if (key.startsWith("message")) 
                                messagesList.push(error.response.data[key]);                                                   
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setMessages(messagesList);
                });
        });

      
    }

    const onCustomerBind = (id) => setCustomerRef(id);


    if (isRedirectToEquipment && isGoingToAddNewReportDirectly)
        return <Redirect to={"/CscDvCert/Add/&equipmentRef=" + id}></Redirect>

    if (isRedirectToEquipment) {
        return <Redirect to={"/EquipmentCscDv/"}></Redirect>
    }


    return (

        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-9 col-12 mx-auto position-relative">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fa fa-wrench fa-fw" aria-hidden="true"></i>
                            </div>
                            <h6 className="mb-0">{PAGE_TITLE}</h6>
                        </div> 
                        <div className="card-body pt-2">
                            <div class="row">
                                {isEdit == true &&
                                    <div className="form-group">
                                        <label>
                                            {t.cecId}
                                        </label>
                                        <p className="form-text text-muted ms-1">
                                            {/* {cECSN} */}
                                        </p>
                                    </div>
                                }
                            </div>
                            <div class="row">
                                <div className="form-group">
                                    <label>
                                        Customer Name
                                    </label>
                                    <UserDefaultCustomer onBind={onCustomerBind} name="CustomerRef" user={user} />
                                </div>
                            </div>
                            {isEdit == false &&
                                <div className="d-flex mt-4">
                                    <button type="button" class="btn btn-light m-0" onClick={autoFill}>
                                        Auto Fill
                                    </button>                                
                                </div>
                            }

                            <div className="col-12">
                            <div className="input-group input-group-static my-3">
                                <label>ContainerNo</label>
                                <input
                                type="text"
                                className="form-control"
                                name="containerNo"
                                onChange={onContainerNoChange}
                                value={containerNo}
                                />
                            </div>
                            </div>
                            <div className="col-12">
                            <div className="input-group input-group-static my-3">
                                <label>ContainerType</label>
                                <input
                                type="text"
                                className="form-control"
                                name="containerType"
                                onChange={onContainerTypeChange}
                                value={containerType}
                                />
                            </div>
                            </div>
                            <div className="col-12">
                            <div className="input-group input-group-static my-3">
                                <label>ManufacturerName</label>
                                <input
                                type="text"
                                className="form-control"
                                name="manufacturerName"
                                onChange={onManufacturerNameChange}
                                value={manufacturerName}
                                />
                            </div>
                            </div>
                            <div className="col-12">
                            <div className="input-group input-group-static my-3">
                                <label>ManufacturerNo</label>
                                <input
                                type="text"
                                className="form-control"
                                name="manufacturerNo"
                                onChange={onManufacturerNoChange}
                                value={manufacturerNo}
                                />
                            </div>
                            </div>
                            <div className="col-12">
                            <div className="input-group input-group-static my-3">
                                <label>ApprovalNo</label>
                                <input
                                type="text"
                                className="form-control"
                                name="approvalNo"
                                onChange={onApprovalNoChange}
                                value={approvalNo}
                                />
                            </div>
                            </div>
                            <div className="col-12">
                            <div className="input-group input-group-static my-3">
                                <label>YearBuilt</label>
                                <input
                                type="text"
                                className="form-control"
                                name="yearBuilt"
                                onChange={onYearBuiltChange}
                                value={yearBuilt}
                                />
                            </div>
                            </div>
                            <div className="col-12">
                            <div className="input-group input-group-static my-3">
                                <label>CscSafetyApprovalNo</label>
                                <input
                                type="text"
                                className="form-control"
                                name="cscSafetyApprovalNo"
                                onChange={onCscSafetyApprovalNoChange}
                                value={cscSafetyApprovalNo}
                                />
                            </div>
                            </div>
                            <div className="col-12">
                            <div className="input-group input-group-static my-3">
                                <label>ClassificationSociety</label>
                                <input
                                type="text"
                                className="form-control"
                                name="classificationSociety"
                                onChange={onClassificationSocietyChange}
                                value={classificationSociety}
                                />
                            </div>
                            </div>


                            {messages.length > 0 &&
                                <Alert messages={messages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={cancel}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={addEdit}>
                                    Save
                                </button>
                                { !isEdit &&
                                <button type="button" class="btn btn-primary m-0 ms-2" onClick={addAndGoToNewReport}>
                                    Save & Add new report
                                </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >

    )

}

export default EquipmentCscDv_Add;