import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Config from '../Constants/index'
import t from '../Lang/Index'
import { getAuth, onAuthStateChanged,getIdToken } from "firebase/auth";

export default class SideBar extends Component {

  constructor() {
    super();

    this.state = { permissions: [], menuGroups: [] }
  }

  componentWillMount() {
    getIdToken(this.props.user).then((idToken)=> { 
      Axios.get(Config.API_URL + "Auth/Permissons/Menu2", {
        headers: {
          'Authorization': 'Bearer ' + idToken
        }
      }).then(response => {
        this.setState({ permissions: response.data });
        this.setState({ menuGroups: [...new Set(response.data.map(item => item.menuGroup))] });
      });
    });
  }

  render() { 

    return (
      <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-dark" id="sidenav-main">
        {/* <div className="sidenav-header">
          <i className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" 
            id="iconSidenav"  />
          <a className="navbar-brand m-0" href="#">
            <img src="/images/logo.png" className="navbar-brand-img h-100" alt="main_logo" />
            <span className="ms-1 font-weight-bold text-white">{Config.SITE_TITLE}</span>
          </a>
        </div> */}


        <div className="sidenav-header">
  <i
    className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
    aria-hidden="true"
    id="iconSidenav"
  />
  <a
    className="navbar-brand px-4 py-3 m-0"
    href=" https://demos.creative-tim.com/material-dashboard-pro/pages/dashboards/analytics.html "
    target="_blank"
  >
    <img
      src="/images/logo.png"
      className="navbar-brand-img"
      width={26}
      height={26}
      alt="main_logo"
    />
    <span className="ms-1 text-sm text-white">{Config.SITE_TITLE}</span>
  </a>
</div>




        <hr className="horizontal light mt-0 mb-2" />
        <div className="collapse navbar-collapse  w-auto  max-height-vh-100" id="sidenav-collapse-main">
          <ul className="navbar-nav">

            <li className="nav-item">
              <Link className="nav-link text-white active bg-gradient-primary" to="/">
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <i className="fas fa-fw fa-tachometer-alt" />
                </div>
                <span className="nav-link-text ms-1">{t.dashboard}</span>
              </Link>
            </li>

            {this.state.menuGroups.map(menuGroup => {
              return (
                <div>

                  {this.state.permissions.filter(item => item.menuGroup == menuGroup && item.menuGroup == null).map(permission => {
                    return (
                      <li className="nav-item"> {
                        (permission.url.toLowerCase().startsWith("http")) ?
                          <a target="_blank" className="nav-link" href={permission.url}><i className={"fa " + permission.icon + " fa-fw"} /><span>{permission.title}</span></a>
                          :
                          <Link className="nav-link" to={permission.url}><i className={"fa " + permission.icon + " fa-fw"} /><span>{permission.title}</span></Link>
                      }
                      </li>
                    )
                  })
                  }
                  {(menuGroup != null) &&
                    <li className="nav-item">
                      <a data-bs-toggle="collapse" href={"#collapsePages" + menuGroup} className="nav-link text-white collapsed" aria-controls={"collapsePages" + menuGroup} role="button" aria-expanded="false">
                        {/* <i className="material-icons-round {% if page.brand == 'RTL' %}ms-2{% else %} me-2{% endif %}">view_in_ar</i> */}
                        <i className="fas fa-fw fa-folder" />
                        <span className="nav-link-text ms-2 ps-1">{menuGroup}</span>
                      </a>
                      <div className="collapse" id={"collapsePages" + menuGroup} style={{}}>
                        <ul className="nav ">

                          {this.state.permissions.filter(item => item.menuGroup == menuGroup && item.menuGroup != null).map(permission => {
                            return (
                              (permission.url.toLowerCase().startsWith("http")) ?
                                <a target="_blank" className="nav-link" href={permission.url}><i className={"fa " + permission.icon + " fa-fw"} /><span> {permission.title}</span></a>
                                :
                                <li className="nav-item ">
                                  <Link className="nav-link text-white" to={permission.url}>
                                    <span className="sidenav-mini-icon"> <i className={"fa " + permission.icon + " fa-fw"} /> </span>
                                    <span className="sidenav-normal  ms-2  ps-1"> {permission.title} </span>
                                  </Link>
                                </li>
                            )
                          })}
                        </ul>
                      </div>
                    </li>
                  }
                </div>
              )
            })
            }
          </ul>
        </div>
      </aside>
    )
  }
}