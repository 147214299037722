import React from 'react';

const DateInputField = ({ label, value, onChange, placeholder = '', required = false }) => {

    const formattedValue = value ? value.split('T')[0] : '';

    const handleChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="input-group input-group-static my-3">
                    <label>
                        {label}
                    </label>
                    <input
                        type="date"
                        className="form-control"
                        value={formattedValue }
                        onChange={handleChange}
                        placeholder={placeholder}
                        required={required}
                    />
                </div>
            </div>    
        </div>
    );
};

export default DateInputField;
