import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert'
import CalibrationTypeLkp from '../../Component/Calibration/CalibrationTypeLkp';
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer';
import UnitOfMeasurmentLkp from '../../Component/Calibration/UnitOfMeasurmentLkp';
import t from '../../Lang/Index'
import ReportTemplateLkp from '../../Component/Calibration/ReportTemplateLkp';
import CalibrationProcedureLkp from '../../Component/Calibration/CalibrationProcedureLkp';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import CustomerLkp from '../../Component/CustomerLkp';
import EquipmentLkp from '../../Component/EquipmentLkp';
import UserLkp from '../../Component/UserLkp';
import moment from 'moment';
import DueDateOfCalibration from './DueDateOfCalibration';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
// import CscDvStatusTemplateLkp from '../../Component/Calibration/CscDvStatusTemplateLkp';

const CscDvCert_Add = (props) => {

    const PAGE_TITLE = "CscDv Report";
    const ControllerName = "CscDvCert";
    const [id, setId] = React.useState(props.match.params.id);
    const isEdit = props.match.params.id > 0;
   
    const [isRedirectCertificateOfCalibration, setIsRedirectCertificateOfCalibration] = React.useState(false);
    const [messages, setMessages] = React.useState([]);
    const [customerRef, setCustomerRef] = React.useState(-1);   
    const [inspUserRef, setInspUserRef] = React.useState(86); 
    const [dueDateOfCalibration, setDueDateOfCalibration] = React.useState('');
    const [dateOfIssue, setDateOfIssue] = React.useState('');    
    const [vCustomerName, setVCustomerName] = React.useState('');
    const [certificateNumber, setCertificateNumber] = React.useState('');
    const [saveEnabled, setSaveEnabled] = React.useState(true);


    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const onCustomerBind = (id) => setCustomerRef(id);
    const onDateOfIssueChange = (e) => setDateOfIssue(e.target.value);
    
    const onDueDateOfCalibrationHandler = (e) =>  setDueDateOfCalibration(e.target.value);
    
    const onDueDateOfCalibrationChange = (dueDate) => {
        setDueDateOfCalibration(dueDate);
    }

    const cancel = () => setIsRedirectCertificateOfCalibration(true);

    React.useEffect(() => {
        
        if (isEdit)
            fetchData();

    },[]);

    const fetchData = () => {
        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + ControllerName + "/GetEntityRow/?id=" + id + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    const item = response.data;
                    debugger;
                    setVCustomerName(item.vCustomerName);
                    setCertificateNumber(item.certificateNumber);
                    setDateOfIssue(item.vDateOfIssue);                    
                    setDueDateOfCalibration(item.vDueDateOfCalibration);                    
                });
        });
    }

    const addEdit = () => {
        setSaveEnabled(false);

        if (isEdit)
            edit();
        else
            add();
    }

    const add = () => {
        let messagesList = [];
        let self = this;

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "CscDvCert/add",
                {
                    CustomerRef: customerRef,
                    DateOfIssue: dateOfIssue == '' ? '1900-01-01' : dateOfIssue,                    
                    DueDateOfInsp: dueDateOfCalibration == '' ? '1900-01-01' : dueDateOfCalibration,
                    EquipmentCscDvRef: props.match.params.equipmentRef,
                    InspUserRef: inspUserRef,                    

                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let id = response.data.id;
                    setId(id);
                    
                    //setCertOfCalRef(response.data.certOfCalRef);

                    setIsRedirectCertificateOfCalibration(true);
                    setSaveEnabled(true);
                })
                .catch(error => {
                    debugger;
                    if (error.response && error.response.status == 400) {
                        for (var key in error.response.data) 
                            if (key.startsWith("message")) 
                                messagesList.push(error.response.data[key]);                                                   
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setMessages(messagesList);
                    setSaveEnabled(true);

                    //Is insert TODO

                });
        });

    }

    const edit = () => {
        let messagesList = [];

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "CscDvCert/Edit",
                {
                    Id: id,
                    CustomerRef: customerRef,                    
                    DateOfIssue: dateOfIssue == '' ? '1900-01-01' : dateOfIssue,
                    DueDateOfInsp: dueDateOfCalibration == '' ? '1900-01-01' : dueDateOfCalibration,
                    EquipmentRef: props.match.params.equipmentRef,
                    InspUserRef: inspUserRef,                    
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {                    
                    setSaveEnabled(true);
                    setIsRedirectCertificateOfCalibration(true);

                })
                .catch(error => {
                    debugger;
                    if (error.response && error.response.status == 400) {
                        for (var key in error.response.data) 
                            if (key.startsWith("message")) 
                                messagesList.push(error.response.data[key]);                                                   
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setMessages(messagesList);
                    setSaveEnabled(true);


                });
        });
    }

   

    if (isRedirectCertificateOfCalibration) 
        return <Redirect to={"/CscDvCert/"}></Redirect>  

    return (

       <>
       <h1>dsssssssssssssssssssssssssssssssssssssssssss dsssssssssssssssssssss</h1>
       </>
    )
}

export default CscDvCert_Add;