import React from 'react';

const TextInputField = ({ label, value, onChange, placeholder = '', required = false }) => {
   
    const handleChange = (e) => {
       onChange(e.target.value);
    };

    return (
        <div class="row">
            <div className="col-12">
                <div className="input-group input-group-static my-3">
                    <label>
                        {label} 
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        value={value}
                        onChange={handleChange}
                        placeholder={placeholder}
                        required={required}
                    />
                </div>
            </div>    
        </div>
    );
};

export default TextInputField;
