import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css"; // Core grid CSS
import "ag-grid-community/dist/styles/ag-theme-alpine.css"; // Optional theme CSS
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import Config from '../../Constants/index';
import { Redirect } from 'react-router-dom';
import { handleErrorResponse } from '../../utils/errorHandler';
import axios from 'axios';

const MyAgGrid = ({ onSelect }) => {

    const PAGE_TITLE = "Countries";
    const ENTITY_NAME = 'Country';

    const [lastSelectedItem, setLastSelectedItem] = React.useState([]);
    const [errorMessages, setErrorMessages] = useState();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const gridRef = React.useRef();

    const columnDefs = [
        { field: 'name', sortable: true, filter: true },
        { field: 'code', sortable: true, filter: true }
    ];

    const datasource = {
        getRows: (params) => {
            console.log('Requesting rows from server', params);

            const query = new URLSearchParams({
                startRow: params.startRow,
                endRow: params.endRow,
                sortModel: JSON.stringify(params.sortModel),
                filterModel: JSON.stringify(params.filterModel),
            });

            fetch(Config.API_URL + ENTITY_NAME + `?${query}`)
                .then((response) => response.json())
                .then((data) => {
                    params.successCallback(data.rows, data.total);
                })
                .catch((error) => {
                    console.error(error);
                    setErrorMessages(error, setErrorMessages)
                    params.failCallback();
                });
        },
    };


    const onGridReady = (params) => {
        params.api.setDatasource(datasource);

        params.api.addEventListener('paginationChanged', () => {
            params.api.deselectAll(); // Clears all selections
            //setLastSelectedItem([]); // Reset state for selected item
        });
    };

    const onRowSelected = (event) => {
        if (event.node.isSelected()) {
            console.log("Selected Row Data:", event.data);
            setLastSelectedItem(event.data);
            onSelect(event.data);
            closeModals();
        }
    };

    const openDialog = () => {
        setIsDialogOpen(true);
    }

    const closeModals = () => {
        setIsDialogOpen(false);
    }

    return (
        <>
            <div class="row">
                <div className="col-12">
                    <div className="input-group input-group-static my-3">
                        <label>
                            Country {lastSelectedItem.id}
                        </label>
                    </div>

                </div>
            </div>



            <div className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                <div className="d-flex flex-column"
                >
                    <h6 className="mb-1 text-dark font-weight-bold text-sm">
                        {lastSelectedItem.id && <>
                        {lastSelectedItem.name + ' (' + lastSelectedItem.code + ')' }
                        </>
                        }                       
                    </h6>
                    {/* <span className="text-xs">#MS-415646</span> */}
                </div>
                <div className="d-flex align-items-center text-sm">
                    <button type="button" class="btn btn-primary m-0 ms-2" onClick={openDialog}>
                        Select
                    </button>
                </div>
            </div>





            {(isDialogOpen) && <div
                className="modal fade show"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
                <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5
                                className="modal-title font-weight-normal"
                                id="exampleModalLabel"
                            >
                                Select a Country
                            </h5>
                            <button
                                type="button"
                                className="btn-close text-dark"
                                onClick={closeModals}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div
                                className="ag-theme-alpine"
                                style={{ height: '300px', margin: "auto" }}
                            >
                                <AgGridReact
                                    ref={gridRef}
                                    columnDefs={columnDefs}
                                    rowSelection="single"
                                    onRowSelected={onRowSelected} // Callback for row selection
                                    rowModelType="infinite"
                                    cacheBlockSize={10} // Number of rows per request
                                    maxBlocksInCache={5} // Maximum number of blocks to keep in cache
                                    onGridReady={onGridReady}
                                    pagination={true} // Enable pagination
                                    paginationPageSize={10} // Number of rows per page
                                    getRowId={(params) => params.data.id} // Ensure this matches your row's `id` property
                                ></AgGridReact>
                            </div>

                        </div>
                    </div>
                </div>
            </div>}


        </>

    );
};

export default MyAgGrid;
