import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Config from '../../Constants/index';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import TextInputField from '../../components/common/TextInputField';
import { Redirect } from 'react-router-dom'
import Alert from '../../Component/Alert'
import { handleErrorResponse } from '../../utils/errorHandler'
import CountryLkp from '../../Pages/Settings/CountryLkp'

const AddEntityForm = (props) => {

    const PAGE_TITLE = 'New State';
    const ENTITY_NAME = 'State';
    const NEXTSTEP_ENTITY_NAME = '';

    const [entity, setEntity] = useState({
        name: '',
        code: '',
        countryId:0,
    });

    const [id, setId] = React.useState(props.match.params.id);
    const isEdit = props.match.params.id > 0;
    const [isRedirectToGrid, setIsRedirectToGrid] = React.useState(false)
    const [isRedirectToNextStep, setIsRedirectToNextStep] = React.useState(false)
    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {
        if (isEdit) {
            fetchEntity();
        }
    }, []);

    const fetchEntity = async () => {
        try {
            const response = await axios.get(`${Config.API_URL}${ENTITY_NAME}/${id}`);
            const entityData = response.data;
            setEntity({
                name: entityData.name,
                code: entityData.code
            });
        } catch (error) {
            handleErrorResponse(['Failed to load data for editing. Please try again.'], setErrorMessages);
        }
    };

    const addEntity = async (onSuccess) => {
        try {
            const response = await axios.post(`${Config.API_URL}${ENTITY_NAME}`, entity);
            setId(response.data.id); // Update the ID after adding the entity
            setErrorMessages([]);
            if (onSuccess) onSuccess(response.data.id);
        } catch (error) {
            handleErrorResponse(error, setErrorMessages);
        }
    };

    const updateEntity = async (onSuccess) => {
        try {
            const response = await axios.put(`${Config.API_URL}${ENTITY_NAME}/${id}`, entity);
            setErrorMessages([]);
            if (onSuccess) onSuccess(response.data.id);
        } catch (error) {
            handleErrorResponse(error, setErrorMessages);
        }
    };

    const handleSave = () => {
        const onSuccess = () => setIsRedirectToGrid(true);
        if (isEdit) {
            updateEntity(onSuccess);
        } else {
            addEntity(onSuccess);
        }
    };

    const handleSaveAndNext = () => {
        if (isEdit) {
            const onSuccess = () => { return; }
            updateEntity(onSuccess);
        } else {
            const onSuccess = () => setIsRedirectToNextStep(true);
            addEntity(onSuccess);
        }
    };

    const cancel = () => {
        setIsRedirectToGrid(true);
    }


    if (isRedirectToGrid)
        return <Redirect to={"/" + ENTITY_NAME + "/"} />

    if (isRedirectToNextStep)
        return <Redirect to={"/" + NEXTSTEP_ENTITY_NAME + "/Add/&PreStepEntityRef=" + id} />

    const handleCountrySelect = (country) => {
        setEntity((prev) => ({ ...prev, country })); // Update the selected country
        setEntity({ ...entity, countryId: country.id });
    };

    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-3 col-12 mx-auto position-relative">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fa fa-wrench fa-fw" aria-hidden="true"></i>
                            </div>
                            <h6 className="mb-0">{PAGE_TITLE}</h6>
                        </div>
                        <div className="card-body pt-2">

                            <TextInputField label={"Name"}
                                value={entity.name}
                                onChange={(value) => setEntity({ ...entity, name: value })}
                                placeholder="Enter name"
                            ></TextInputField>

                            <TextInputField label={"Code"}
                                value={entity.code}
                                onChange={(value) => setEntity({ ...entity, code: value })}
                                placeholder="Enter Code"
                            ></TextInputField>


                            <CountryLkp onSelect={handleCountrySelect} />
       
                            {errorMessages.length > 0 &&
                                <Alert messages={errorMessages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={cancel}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={handleSave}>
                                    Save
                                </button>
                                {((!isEdit && NEXTSTEP_ENTITY_NAME)) &&
                                    <button type="button" class="btn btn-primary m-0 ms-2" onClick={handleSaveAndNext}>
                                        Save & Next Step
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >
    );
};

export default AddEntityForm;
