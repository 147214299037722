import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert'
import CalibrationTypeLkp from '../../Component/Calibration/CalibrationTypeLkp';
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer';
import UnitOfMeasurmentLkp from '../../Component/Calibration/UnitOfMeasurmentLkp';
import t from '../../Lang/Index'
import ReportTemplateLkp from '../../Component/Calibration/ReportTemplateLkp';
import CalibrationProcedureLkp from '../../Component/Calibration/CalibrationProcedureLkp';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import CustomerLkp from '../../Component/CustomerLkp';
import EquipmentLkp from '../../Component/EquipmentLkp';
import UserLkp from '../../Component/UserLkp';
import moment from 'moment';
import DueDateOfCalibration from './DueDateOfCalibration';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth'
// import CscStatusTemplateLkp from '../../Component/Calibration/CscStatusTemplateLkp';

const CscCert_Add = (props) => {

    const PAGE_TITLE = "CSC Report";
    const ControllerName = "CecCert";
    const [id, setId] = React.useState(props.match.params.id);
    const isEdit = props.match.params.id > 0;
   
    const [isRedirectCertificateOfCalibration, setIsRedirectCertificateOfCalibration] = React.useState(false);
    const [messages, setMessages] = React.useState([]);
    const [customerRef, setCustomerRef] = React.useState(-1);
    const [inspUserRef, setInspUserRef] = React.useState(86); //TODO
    const [dateOfCalibration, setDateOfCalibration] = React.useState('');
    const [dueDateOfCalibration, setDueDateOfCalibration] = React.useState('');
    const [dateOfIssue, setDateOfIssue] = React.useState('');
    const [jobNo, setJobNo] = React.useState('');
    const [vCustomerName, setVCustomerName] = React.useState('');
    const [certificateNumber, setCertificateNumber] = React.useState('');
    const [saveEnabled, setSaveEnabled] = React.useState(true);
    const [isGoingToAddResultPageDirectly, setIsGoingToAddResultPageDirectly] = React.useState(false);
    const [certOfCalRef, setCertOfCalRef] = React.useState(-1);

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const onCustomerBind = (id) => setCustomerRef(id);
    const onDateOfCalibrationChange = (e) => setDateOfCalibration(e.target.value);
    const onDateOfIssueChange = (e) => setDateOfIssue(e.target.value);
    const onTestChange = (e) => setJobNo(e.target.value);
    
    const onDueDateOfCalibrationHandler = (e) =>  setDueDateOfCalibration(e.target.value);
    
    const onDueDateOfCalibrationChange = (dueDate) => {
        setDueDateOfCalibration(dueDate);
    }

    const cancel = () => setIsRedirectCertificateOfCalibration(true);

    React.useEffect(() => {
        
        if (isEdit)
            fetchData();

    },[]);

    const fetchData = () => {
        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + ControllerName + "/GetEntityRow/?id=" + id + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    const item = response.data;
                    debugger;
                    setVCustomerName(item.vCustomerName);
                    setCertificateNumber(item.certificateNumber);
                    setDateOfCalibration(item.vDateOfCalibration);
                    setDueDateOfCalibration(item.vDueDateOfCalibration);
                    //setIspUserRef(item.calibrationUserRef);                    
                    setDateOfIssue(item.vDateOfIssue);
                    //setJobNo(item.jobNo);                    
                });
        });
    }

    const addEdit = () => {
        setSaveEnabled(false);

        if (isEdit)
            edit();
        else
            add();
    }

    const addAndGoToResultPage = () => {
        setIsGoingToAddResultPageDirectly(true);
        setSaveEnabled(false);      
        if (isEdit)
            edit();  
        else
            add();
    }


    const add = () => {
        let messagesList = [];
        let self = this;

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "CscCert/add",
                {
                    CustomerRef: customerRef,
                    DateOfInsp: dateOfCalibration == '' ? '1900-01-01' : dateOfCalibration,
                    DueDateOfInsp: dueDateOfCalibration == '' ? '1900-01-01' : dueDateOfCalibration,
                    EquipmentCscRef: props.match.params.equipmentRef,
                    InspUserRef: inspUserRef,                    
                    DateOfIssue: dateOfIssue == '' ? '1900-01-01' : dateOfIssue,
                    //Test: test
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let id = response.data.id;
                    setId(id);

                    setCertOfCalRef(response.data.certOfCalRef);

                    setIsRedirectCertificateOfCalibration(true);
                    setSaveEnabled(true);
                })
                .catch(error => {
                    debugger;
                    if (error.response && error.response.status == 400) {
                        for (var key in error.response.data) 
                            if (key.startsWith("message")) 
                                messagesList.push(error.response.data[key]);                                                   
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setMessages(messagesList);
                    setSaveEnabled(true);

                    //Is insert TODO

                });
        });

    }

    const edit = () => {
        let messagesList = [];

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "CscCert/Edit",
                {
                    Id: id,
                    CustomerRef: customerRef,
                    DateOfCalibration: dateOfCalibration == '' ? '1900-01-01' : dateOfCalibration,
                    DueDateOfCalibration: dueDateOfCalibration == '' ? '1900-01-01' : dueDateOfCalibration,
                    EquipmentRef: props.match.params.equipmentRef,
                    InspUserRef: inspUserRef,                    
                    DateOfIssue: dateOfIssue == '' ? '1900-01-01' : dateOfIssue,
                    Test: test
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {                    
                    setSaveEnabled(true);
                    setIsRedirectCertificateOfCalibration(true);

                })
                .catch(error => {
                    if (error.response && error.response.status == 400) {
                        error.response.data.message.map(p => {
                            messagesList.push(p);
                        })
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setSaveEnabled(true);

                    setMessages(messagesList);
                });
        });
    }

   

    if (isRedirectCertificateOfCalibration) 
        return <Redirect to={"/CscCert/"}></Redirect>  

    return (

        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-9 col-12 mx-auto position-relative">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fa fa-wrench fa-fw" aria-hidden="true"></i>
                            </div>
                            {!isEdit &&
                                <h6 className="mb-0">New {PAGE_TITLE} </h6>
                            }
                            {isEdit &&
                                <>
                                    <h6 className="mb-0">Edit {PAGE_TITLE}</h6>
                                    <p className="mb-0 font-weight-normal text-sm">
                                        {certificateNumber}
                                    </p>
                                </>
                            }

                        </div>


                        <div className="card-body pt-2">


                            <div class="row">
                                <div className="form-group">
                                    <label>
                                        Customer Name
                                    </label>

                                    {!isEdit &&
                                        <UserDefaultCustomer onBind={onCustomerBind} name="CustomerRef" user={user} />
                                    }
                                    {isEdit &&
                                        <p className="form-text text-muted ms-1">
                                            {vCustomerName}
                                        </p>
                                    }
                                </div>
                            </div>


                            <div class="row">
                                <div className="col-3">
                                    <div className="input-group input-group-static  my-3">
                                        <label>
                                            Date Of Calibration
                                        </label>
                                        <input type="date" class="form-control" name="dateOfCalibration" onChange={onDateOfCalibrationChange}
                                            value={dateOfCalibration} />
                                    </div>
                                </div>
                            </div>


                            {isEdit &&
                            <div class="row">
                                <div className="col-3">
                                    <div className="input-group input-group-static  my-3">
                                        <label>
                                            Due Date Of Calibration
                                        </label>
                                        <input type="date" class="form-control" name="dueDateOfCalibration" onChange={onDueDateOfCalibrationHandler}
                                            value={dueDateOfCalibration} />
                                    </div>
                                </div>
                            </div>}
                            {!isEdit &&
                            <DueDateOfCalibration dateOfCalibration={dateOfCalibration}
                                name="dueDateOfCalibration" onDueDateOfCalibrationChange={onDueDateOfCalibrationChange}
                                value={dueDateOfCalibration} />
                            }

                            {/* <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Calibration User
                                    </label>
                                    <UserLkp name="CalibrationUserRef" onChange={onCalibrationUserRefChange} value={calibrationUserRef} user={user} />
                                </div>
                            </div> */}

                            <div className="col-3">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Date Of Issue
                                    </label>
                                    <input type="date" class="form-control" name="dateOfIssue" onChange={onDateOfIssueChange} value={dateOfIssue} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        Test
                                    </label>
                                    <input type="text" class="form-control" name="test" onChange={onTestChange} value={jobNo} />
                                </div>
                            </div>
                            
 
                            {/* <div className="col-12">
                                <div className="input-group input-group-static  my-3">
                                    <label for="exampleInputEmail">
                                        <input type="checkbox" name="damaged" onChange={onSatisfiedChange}
                                            value={satisfied} checked={satisfied}  /> Satisfied
                                    </label>
                                </div>
                            </div> */}
                          


                            {messages.length > 0 &&
                                <Alert messages={messages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={cancel}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={addEdit} disabled={!saveEnabled} >
                                    Save
                                </button>                                                     
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >
    )
}

export default CscCert_Add;