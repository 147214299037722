import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Config from '../../Constants/index';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import TextInputField from '../../components/common/TextInputField';
import DateInputField from '../../components/common/DateInputField';
import { Redirect } from 'react-router-dom'
import Alert from '../../Component/Alert'
import { handleErrorResponse } from '../../utils/errorHandler'
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';


const AddEntityForm = (props) => {

    /*Begin******************/

    const PAGE_TITLE = 'New IsoTank22T2';
    const ENTITY_NAME = 'EquipmentCscIsoTank22T2';
    const NEXTSTEP_ENTITY_NAME = 'CscIsoTank22T2Cert';

    const [entity, setEntity] = useState({
        isoTankNo: '',
    });

    const mapEntityData = (data) => {
        setEntity((prevState) => ({
            ...prevState,
            isoTankNo: data.isoTankNo,
            tankType: data.tankType,
            cargoName: data.cargoName,
            specialProvisions: data.specialProvisions,
            approvedForTransport: data.approvedForTransport,
            cscSafetyApproval: data.cscSafetyApproval,
            tankModel: data.tankModel,
            maxWorkingPressure: data.maxWorkingPressure,
            testPressure: data.testPressure,
            steamTubeWorkingPressure: data.steamTubeWorkingPressure,
            steamTubeTestPressure: data.steamTubeTestPressure,
            designTemperature: data.designTemperature,
            tankMaterial: data.tankMaterial,
            tankThickness: data.tankThickness,
            maxGrossWeight: data.maxGrossWeight,
            tare: data.tare,
            capacity: data.capacity,
            ownerCodeSerialNo: data.ownerCodeSerialNo,
            manufacturerCodeSerialNo: data.manufacturerCodeSerialNo,
            designStandard: data.designStandard,
            manufacturer: data.manufacturer,
            dischargeTop: data.dischargeTop,
            dischargeBottom: data.dischargeBottom,
            heater: data.heater,
            steamValves: data.steamValves,
            dateManufactured: data.dateManufactured,
            intialInspection: data.intialInspection,
            lastHydraulicTestDate: data.lastHydraulicTestDate,
            lastLeakProofnessTestDate: data.lastLeakProofnessTestDate
        }));
    };

    /*end******************/

    const [id, setId] = React.useState(props.match.params.id);
    const isEdit = props.match.params.id > 0;
    const [isRedirectToGrid, setIsRedirectToGrid] = React.useState(false)
    const [isRedirectToNextStep, setIsRedirectToNextStep] = React.useState(false)
    const [errorMessages, setErrorMessages] = useState([]);

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);


    useEffect(() => {
        if (isEdit) {
            fetchEntity();
        }
    }, []);

    const fetchEntity = async () => {
        try {
            const idToken = await getIdToken(user); // Get the token inside the method
            const response = await axios.get(
                `${Config.API_URL}${ENTITY_NAME}/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                    },
                }
            );
            mapEntityData(response.data);
        } catch (error) {
            handleErrorResponse(['Failed to load data for editing. Please try again.'], setErrorMessages);
        }
    };


    const addEntity = async (onSuccess) => {
        try {
            const idToken = await getIdToken(user);

            const response = await axios.post(
                `${Config.API_URL}${ENTITY_NAME}`,
                entity,
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }
            );

            setId(response.data.id); // Update the ID after adding the entity
            setErrorMessages([]);
            if (onSuccess) onSuccess(response.data.id);
        } catch (error) {
            handleErrorResponse(error, setErrorMessages);
        }
    };

    const updateEntity = async (onSuccess) => {
        try {
            const idToken = await getIdToken(user); // Get the token inside the method
            const response = await axios.put(
                `${Config.API_URL}${ENTITY_NAME}/${id}`,
                entity,
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                    },
                }
            );
            setErrorMessages([]);
            if (onSuccess) onSuccess(response.data.id);
        } catch (error) {
            handleErrorResponse(error, setErrorMessages);
        }
    };

    const handleSave = () => {
        const onSuccess = () => setIsRedirectToGrid(true);
        if (isEdit) {
            updateEntity(onSuccess);
        } else {
            addEntity(onSuccess);
        }
    };

    const handleSaveAndNext = () => {
        if (isEdit) {
            const onSuccess = () => { return; }
            updateEntity(onSuccess);
        } else {
            const onSuccess = () => setIsRedirectToNextStep(true);
            addEntity(onSuccess);
        }
    };

    const cancel = () => {
        setIsRedirectToGrid(true);
    }


    if (isRedirectToGrid)
        return <Redirect to={"/" + ENTITY_NAME + "/"} />

    if (isRedirectToNextStep)
        return <Redirect to={"/" + NEXTSTEP_ENTITY_NAME + "/Add/&PreStepEntityRef=" + id} />

    const handleChange = (field, value) => {
        setEntity({ ...entity, [field]: value });
    };

    const autoFill = () => {
        setEntity({
            
            tankType: "PORTABLE TANK 22T2",
            cargoName: "N/A",
            specialProvisions: "N/A",
            approvedForTransport: "CNC/CSN/023/2012",
            cscSafetyApproval: "CNC/CSN/029/12",
            tankModel: "CX09.26TGX",
            maxWorkingPressure: "1.75 Bar",
            testPressure: "2.65 Bar",
            steamTubeWorkingPressure: "4 Bar",
            steamTubeTestPressure: "6 Bar",
            designTemperature: "-19°C to +121°C",
            tankMaterial: "Q345B",
            tankThickness: "SHELL 5 MM HEAD 6MM",
            maxGrossWeight: "30480 KG",
            tare: "4190 KG",
            capacity: "26000 L",
            ownerCodeSerialNo: "FEPU231173B",
            manufacturerCodeSerialNo: "CXC/162025",
            designStandard: "14993-1995",
            manufacturer: "JIANGSU WANLONG SPECIAL CONTAINERS CO., LTD. CHINA",
            dischargeTop: "No",
            dischargeBottom: "Yes",
            heater: "NA",
            steamValves: "2",
            dateManufactured: "2012-05-01", // Converted to YYYY-MM-DD
            intialInspection: "2012-05-01", // Converted to YYYY-MM-DD
            lastHydraulicTestDate: "2024-06-01", // Converted to YYYY-MM-DD
            lastLeakProofnessTestDate: "2024-06-01" // Converted to YYYY-MM-DD
        });
    }

    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-6 col-12 mx-auto position-relative">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fa fa-wrench fa-fw" aria-hidden="true"></i>
                            </div>
                            <h6 className="mb-0">{PAGE_TITLE}</h6>
                        </div>

                        {isEdit == false &&
                                <div className="d-flex mt-4">
                                    <button type="button" class="btn btn-light m-0" onClick={autoFill} >
                                        Auto Fill
                                    </button>                                
                                </div>
                            }

                        <div className="card-body pt-2">



                            {/* Begin ****************** */}
                            <TextInputField
                                label={"IsoTankNo"}
                                value={entity.isoTankNo}
                                onChange={(value) => handleChange("isoTankNo", value)}
                            />
                            <TextInputField
                                label={"TankType"}
                                value={entity.tankType}
                                onChange={(value) => handleChange("tankType", value)}
                            />
                            <TextInputField
                                label={"CargoName"}
                                value={entity.cargoName}
                                onChange={(value) => handleChange("cargoName", value)}
                            />
                            <TextInputField
                                label={"SpecialProvisions"}
                                value={entity.specialProvisions}
                                onChange={(value) => handleChange("specialProvisions", value)}
                            />
                            <TextInputField
                                label={"TankModel"}
                                value={entity.tankModel}
                                onChange={(value) => handleChange("tankModel", value)}
                            />
                            <TextInputField
                                label={"DesignStandard"}
                                value={entity.designStandard}
                                onChange={(value) => handleChange("designStandard", value)}
                            />
                            <TextInputField
                                label={"MaxWorkingPressure"}
                                value={entity.maxWorkingPressure}
                                onChange={(value) => handleChange("maxWorkingPressure", value)}
                            />
                            <TextInputField
                                label={"TestPressure"}
                                value={entity.testPressure}
                                onChange={(value) => handleChange("testPressure", value)}
                            />
                            <TextInputField
                                label={"SteamTubeWorkingPressure"}
                                value={entity.steamTubeWorkingPressure}
                                onChange={(value) => handleChange("steamTubeWorkingPressure", value)}
                            />
                            <TextInputField
                                label={"SteamTubeTestPressure"}
                                value={entity.steamTubeTestPressure}
                                onChange={(value) => handleChange("steamTubeTestPressure", value)}
                            />
                            <TextInputField
                                label={"DesignTemperature"}
                                value={entity.designTemperature}
                                onChange={(value) => handleChange("designTemperature", value)}
                            />
                            <TextInputField
                                label={"MaxGrossWeight"}
                                value={entity.maxGrossWeight}
                                onChange={(value) => handleChange("maxGrossWeight", value)}
                            />
                            <TextInputField
                                label={"Tare"}
                                value={entity.tare}
                                onChange={(value) => handleChange("tare", value)}
                            />
                            <TextInputField
                                label={"Capacity"}
                                value={entity.capacity}
                                onChange={(value) => handleChange("capacity", value)}
                            />
                            <TextInputField
                                label={"TankMaterial"}
                                value={entity.tankMaterial}
                                onChange={(value) => handleChange("tankMaterial", value)}
                            />
                            <TextInputField
                                label={"TankThickness"}
                                value={entity.tankThickness}
                                onChange={(value) => handleChange("tankThickness", value)}
                            />
                            <TextInputField
                                label={"OwnerCodeSerialNo"}
                                value={entity.ownerCodeSerialNo}
                                onChange={(value) => handleChange("ownerCodeSerialNo", value)}
                            />
                            <TextInputField
                                label={"ManufacturerCodeSerialNo"}
                                value={entity.manufacturerCodeSerialNo}
                                onChange={(value) => handleChange("manufacturerCodeSerialNo", value)}
                            />
                            <DateInputField
                                label={"DateManufactured"}
                                value={entity.dateManufactured}
                                onChange={(value) => handleChange("dateManufactured", value)}
                            />
                            <TextInputField
                                label={"ApprovedForTransport"}
                                value={entity.approvedForTransport}
                                onChange={(value) => handleChange("approvedForTransport", value)}
                            />
                            <TextInputField
                                label={"CSCSafetyApproval"}
                                value={entity.cscSafetyApproval}
                                onChange={(value) => handleChange("cscSafetyApproval", value)}
                            />                                                                           
                            <TextInputField
                                label={"Manufacturer"}
                                value={entity.manufacturer}
                                onChange={(value) => handleChange("manufacturer", value)}
                            />
                            <DateInputField
                                label={"IntialInspection"}
                                value={entity.intialInspection}
                                onChange={(value) => handleChange("intialInspection", value)}
                            />

                            <TextInputField
                                label={"DischargeTop"}
                                value={entity.dischargeTop}
                                onChange={(value) => handleChange("dischargeTop", value)}
                            />
                            <TextInputField
                                label={"DischargeBottom"}
                                value={entity.dischargeBottom}
                                onChange={(value) => handleChange("dischargeBottom", value)}
                            />
                            <TextInputField
                                label={"Heater"}
                                value={entity.heater}
                                onChange={(value) => handleChange("heater", value)}
                            />
                            <TextInputField
                                label={"SteamValves"}
                                value={entity.steamValves}
                                onChange={(value) => handleChange("steamValves", value)}
                            />                                                 
                            <DateInputField
                                label={"LastHydraulicTestDate"}
                                value={entity.lastHydraulicTestDate}
                                onChange={(value) => handleChange("lastHydraulicTestDate", value)}
                            />
                            <DateInputField
                                label={"LastLeakProofnessTestDate"}
                                value={entity.lastLeakProofnessTestDate}
                                //onChange={(value) => handleChange("lastLeakProofnessTestDate", value)}
                            />


                            {/* End ****************** */}

                            {errorMessages.length > 0 &&
                                <Alert messages={errorMessages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={cancel}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={handleSave}>
                                    Save
                                </button>
                                {((!isEdit && NEXTSTEP_ENTITY_NAME)) &&
                                    <button type="button" class="btn btn-primary m-0 ms-2" onClick={handleSaveAndNext}>
                                        Save & Next Step
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >
    );
};

export default AddEntityForm;
