import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Axios from 'axios';
import Config from '../../Constants/index'
import Alert from '../../Component/Alert'
import CalibrationTypeLkp from '../../Component/Calibration/CalibrationTypeLkp';
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer';
import UnitOfMeasurmentLkp from '../../Component/Calibration/UnitOfMeasurmentLkp';
import t from '../../Lang/Index'
import ReportTemplateLkp from '../../Component/Calibration/ReportTemplateLkp';
import CalibrationProcedureLkp from '../../Component/Calibration/CalibrationProcedureLkp';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';
import moment from "moment";

// import CscManufacurerLkp from '../../Component/Calibration/CscManufacurerLkp';
// import CscValveTypeLkp from '../../Component/Calibration/CscValveTypeLkp';
// import CscBodyMaterialLkp from '../../Component/Calibration/CscBodyMaterialLkp';
// import CscDesingLkp from '../../Component/Calibration/CscDesingLkp';
// import CscTestMediumLkp from '../../Component/Calibration/CscTestMediumLkp';

const EquipmentCsc_Add = (props) => {

    const PAGE_TITLE = "New " + t.cscUuc;
    const DEFUALT_CONTROLLER = "EquipmentCsc";

    const [id, setId] = React.useState(props.match.params.id);
    const isEdit = props.match.params.id > 0;
    
  
    const [customerRef, setCustomerRef] = React.useState(-1);

    const [isoTankNo, setIsoTankNo] = React.useState('');
    const [tankType, setTankType] = React.useState('');
    const [cargoName, setCargoName] = React.useState('');
    const [specialProvisions, setSpecialProvisions] = React.useState('');
    const [imdgImo, setImdgImo] = React.useState('');
    const [adrRid, setAdrRid] = React.useState('');
    const [csc, setCsc] = React.useState('');
    const [usdot, setUsdot] = React.useState('');
    const [manufacturer, setManufacturer] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [isoCode, setIsoCode] = React.useState('');
    const [max, setMax] = React.useState('');
    const [tare, setTare] = React.useState('');
    const [capacity, setCapacity] = React.useState('');
    const [initialInspection, setInitialInspection] = React.useState(null);
    const [approval, setApproval] = React.useState('');
    const [material, setMaterial] = React.useState('');
    const [tankWorkingPressure, setTankWorkingPressure] = React.useState('');
    const [tankExternalPressure, setTankExternalPressure] = React.useState('');
    const [manufacturingThickness, setManufacturingThickness] = React.useState('');
    const [tankOperatingTemp, setTankOperatingTemp] = React.useState('');
    const [discharge, setDischarge] = React.useState('');
    const [heater, setHeater] = React.useState('');
    const [heaterWorkingPressure, setHeaterWorkingPressure] = React.useState('');
    const [steamValves, setSteamValves] = React.useState('');
    const [metallurgicalDesignTemp, setMetallurgicalDesignTemp] = React.useState('');
    const [messages, setMessages] = React.useState([]);
    const [saveEnabled, setSaveEnabled] = React.useState(true);
    const [isGoingToAddNewReportDirectly, setIsGoingToAddNewReportDirectly] = React.useState(false)
    const [isRedirectToEquipment, setIsRedirectToEquipment] = React.useState(false)

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);


    const onCustomerRefChange = (e) => setCustomerRef(e.target.value);
    const onIsoTankNoChange = (e) => setIsoTankNo(e.target.value);
    const onTankTypeChange = (e) => setTankType(e.target.value);
    const onCargoNameChange = (e) => setCargoName(e.target.value);
    const onSpecialProvisionsChange = (e) => setSpecialProvisions(e.target.value);
    const onImdgImoChange = (e) => setImdgImo(e.target.value);
    const onAdrRidChange = (e) => setAdrRid(e.target.value);
    const onCscChange = (e) => setCsc(e.target.value);
    const onUsdotChange = (e) => setUsdot(e.target.value);
    const onManufacturerChange = (e) => setManufacturer(e.target.value);
    const onCountryChange = (e) => setCountry(e.target.value);
    const onIsoCodeChange = (e) => setIsoCode(e.target.value);
    const onMaxChange = (e) => setMax(e.target.value);
    const onTareChange = (e) => setTare(e.target.value);
    const onCapacityChange = (e) => setCapacity(e.target.value);
    const onInitialInspectionChange = (e) => setInitialInspection(e.target.value);
    const onApprovalChange = (e) => setApproval(e.target.value);
    const onMaterialChange = (e) => setMaterial(e.target.value);
    const onTankWorkingPressureChange = (e) => setTankWorkingPressure(e.target.value);
    const onTankExternalPressureChange = (e) => setTankExternalPressure(e.target.value);
    const onManufacturingThicknessChange = (e) => setManufacturingThickness(e.target.value);
    const onTankOperatingTempChange = (e) => setTankOperatingTemp(e.target.value);
    const onDischargeChange = (e) => setDischarge(e.target.value);
    const onHeaterChange = (e) => setHeater(e.target.value);
    const onHeaterWorkingPressureChange = (e) => setHeaterWorkingPressure(e.target.value);
    const onSteamValvesChange = (e) => setSteamValves(e.target.value);
    const onMetallurgicalDesignTempChange = (e) => setMetallurgicalDesignTemp(e.target.value);
    
    
    React.useEffect(() => {
        if (isEdit)
            fetchData();

    }, []);

    const fetchData = () => {
        debugger;
        getIdToken(user).then((idToken) => {
            Axios.get(Config.API_URL + "EquipmentCsc/GetEntityRow/?id=" + id + "&timestamp=" + new Date().getTime(),
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    const item = response.data;
                    debugger;
                    setCustomerRef(item.customerRef);
                    setIsoTankNo(item.isoTankNo);
                    setTankType(item.tankType);
                    setCargoName(item.cargoName);
                    setSpecialProvisions(item.specialProvisions);
                    setImdgImo(item.imdG_IMO);
                    setAdrRid(item.adR_RID);
                    setCsc(item.csc);
                    setUsdot(item.usdot);
                    setManufacturer(item.manufacturer);
                    setCountry(item.country);
                    setIsoCode(item.isoCode);
                    setMax(item.max);
                    setTare(item.tare);
                    setCapacity(item.capacity);
                    
                    if (item.initialInspection)
                    {
                        let d = Date.parse(item.initialInspection)
                        let dateString = moment(d).format('YYYY-MM-DD');
                        setInitialInspection(dateString);
                    }
                    setApproval(item.approval);
                    setMaterial(item.material);
                    setTankWorkingPressure(item.tankWorkingPressure);
                    setTankExternalPressure(item.tankExternalPressure);
                    setManufacturingThickness(item.manufacturingThickness);
                    setTankOperatingTemp(item.tankOperatingTemp);
                    setDischarge(item.discharge);
                    setHeater(item.heater);
                    setHeaterWorkingPressure(item.heaterWorkingPressure);
                    setSteamValves(item.steamValves);
                    setMetallurgicalDesignTemp(item.metallurgicalDesignTemp);
                });
        });
    }
    
    const cancel = () => {
        setIsRedirectToEquipment(true);
    }

    const autoFill = () => {
        // Substances Suitable for Transport
        setTankType('PORTABLE TANK T11');
        setCargoName('Aromatic solvents');
        setSpecialProvisions('N/A');

        // Applicable Regulation
        setImdgImo('GB/IMO/BV/0286J/11');
        setAdrRid('GB/BV/0286J/11');
        setCsc('F/BV/7900/02');
        setUsdot('IA-8106');

        // Characteristics
        setManufacturer('WELFIT ODDY');
        setCountry('SOUTH AFRICA');
        setIsoCode('22 T6');
        setMax('gross mass 36000 kg');
        setTare('3450 kg');
        setCapacity('25000 Lit 6605 US gal');

        setInitialInspection('2012-03-08');
        setApproval('GB/C8648BV/2010');

        // Tank
        setMaterial('STAINLESS STEEL 316L SANS 50028-7 1.4402/1.4404');
        setTankWorkingPressure('4 Bar');
        setTankExternalPressure('0.41 Bar');
        setManufacturingThickness('6 Bar');
        setTankOperatingTemp('min -40 C, max 130 C');

        // Equipment
        setDischarge('Top (No), Bottom (Yes)');
        setHeater('N/A');
        setHeaterWorkingPressure('N/A');
        setSteamValves('2');
        setMetallurgicalDesignTemp('-40 C to 130 C');

    }

    const addAndGoToNewReport = () => {
        setIsGoingToAddNewReportDirectly(true);
        add();
    }

    const addEdit = () => {
        debugger;
        if (isEdit)
            edit();
        else
            add();
    }

    const add = () => {
        let messagesList = [];
        let self = this;

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL +  DEFUALT_CONTROLLER + "/add",
                {
                    CustomerRef: customerRef,
                    IsoTankNo: isoTankNo,
                    TankType: tankType,
                    CargoName: cargoName,
                    SpecialProvisions: specialProvisions,
                    IMDG_IMO: imdgImo,
                    ADR_RID: adrRid,
                    CSC: csc,
                    USDOT: usdot,
                    Manufacturer: manufacturer,
                    Country: country,
                    IsoCode: isoCode,
                    Max: max,
                    Tare: tare,
                    Capacity: capacity,
                    InitialInspection: initialInspection,
                    Approval: approval,
                    Material: material,
                    TankWorkingPressure: tankWorkingPressure,
                    TankExternalPressure: tankExternalPressure,
                    ManufacturingThickness: manufacturingThickness,
                    TankOperatingTemp: tankOperatingTemp,
                    Discharge: discharge,
                    Heater: heater,
                    HeaterWorkingPressure: heaterWorkingPressure,
                    SteamValves: steamValves,
                    MetallurgicalDesignTemp: metallurgicalDesignTemp                    
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let _id = response.data;
                    setId(_id)
                    //TO DO redirecto Calibration Report
                    setIsRedirectToEquipment(true);
                })
                .catch(error => {
                    debugger;
                    if (error.response && error.response.status == 400) {
                        for (var key in error.response.data) 
                            if (key.startsWith("message")) 
                                messagesList.push(error.response.data[key]);                                                   
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setMessages(messagesList);
                });
        });
    }

    const edit = () => {
        let messagesList = [];

        getIdToken(user).then((idToken) => {

            Axios.post(Config.API_URL + "EquipmentCsc/edit",
                {
                    id: id,                    
                    CustomerRef: customerRef,
                    IsoTankNo: isoTankNo,
                    TankType: tankType,
                    CargoName: cargoName,
                    SpecialProvisions: specialProvisions,
                    IMDG_IMO: imdgImo,
                    ADR_RID: adrRid,
                    CSC: csc,
                    USDOT: usdot,
                    Manufacturer: manufacturer,
                    Country: country,
                    IsoCode: isoCode,
                    Max: max,
                    Tare: tare,
                    Capacity: capacity,
                    InitialInspection: initialInspection,
                    Approval: approval,
                    Material: material,
                    TankWorkingPressure: tankWorkingPressure,
                    TankExternalPressure: tankExternalPressure,
                    ManufacturingThickness: manufacturingThickness,
                    TankOperatingTemp: tankOperatingTemp,
                    Discharge: discharge,
                    Heater: heater,
                    HeaterWorkingPressure: heaterWorkingPressure,
                    SteamValves: steamValves,
                    MetallurgicalDesignTemp: metallurgicalDesignTemp                   
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                })
                .then(response => {
                    let _id = response.data;
                    setId(_id)
                    
                    setIsRedirectToEquipment(true);
                })
                .catch(error => {
                    debugger;
                    if (error.response && error.response.status == 400) {
                        for (var key in error.response.data) 
                            if (key.startsWith("message")) 
                                messagesList.push(error.response.data[key]);                                                   
                    }
                    else {
                        messagesList.push("Failed to connect to server")
                    }
                    setMessages(messagesList);
                });
        });

      
    }

    const onCustomerBind = (id) => setCustomerRef(id);


    if (isRedirectToEquipment && isGoingToAddNewReportDirectly)
        return <Redirect to={"/CscCert/Add/&equipmentRef=" + id}></Redirect>

    if (isRedirectToEquipment) {
        return <Redirect to={"/EquipmentCsc/"}></Redirect>
    }


    return (

        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-9 col-12 mx-auto position-relative">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fa fa-wrench fa-fw" aria-hidden="true"></i>
                            </div>
                            <h6 className="mb-0">{PAGE_TITLE}</h6>
                        </div> 
                        <div className="card-body pt-2">
                            <div class="row">
                                {isEdit == true &&
                                    <div className="form-group">
                                        <label>
                                            {t.cecId}
                                        </label>
                                        <p className="form-text text-muted ms-1">
                                            {/* {cECSN} */}
                                        </p>
                                    </div>
                                }
                            </div>
                            <div class="row">
                                <div className="form-group">
                                    <label>
                                        Customer Name
                                    </label>
                                    <UserDefaultCustomer onBind={onCustomerBind} name="CustomerRef" user={user} />
                                </div>
                            </div>
                            {isEdit == false &&
                                <div className="d-flex mt-4">
                                    <button type="button" class="btn btn-light m-0" onClick={autoFill}>
                                        Auto Fill
                                    </button>                                
                                </div>
                            }

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>IsoTankNo</label>
                                    <input type="text" class="form-control" name="isoTankNo" onChange={onIsoTankNoChange} value={isoTankNo} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>TankType</label>
                                    <input type="text" class="form-control" name="tankType" onChange={onTankTypeChange} value={tankType} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>CargoName</label>
                                    <input type="text" class="form-control" name="cargoName" onChange={onCargoNameChange} value={cargoName} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>SpecialProvisions</label>
                                    <input type="text" class="form-control" name="specialProvisions" onChange={onSpecialProvisionsChange} value={specialProvisions} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>IMDG_IMO</label>
                                    <input type="text" class="form-control" name="imdg_imo" onChange={onImdgImoChange} value={imdgImo} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>ADR_RID</label>
                                    <input type="text" class="form-control" name="adr_rid" onChange={onAdrRidChange} value={adrRid} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>CSC</label>
                                    <input type="text" class="form-control" name="csc" onChange={onCscChange} value={csc} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>USDOT</label>
                                    <input type="text" class="form-control" name="usdot" onChange={onUsdotChange} value={usdot} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>Manufacturer</label>
                                    <input type="text" class="form-control" name="manufacturer" onChange={onManufacturerChange} value={manufacturer} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>Country</label>
                                    <input type="text" class="form-control" name="country" onChange={onCountryChange} value={country} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>IsoCode</label>
                                    <input type="text" class="form-control" name="isoCode" onChange={onIsoCodeChange} value={isoCode} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>Max</label>
                                    <input type="text" class="form-control" name="max" onChange={onMaxChange} value={max} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>Tare</label>
                                    <input type="text" class="form-control" name="tare" onChange={onTareChange} value={tare} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>Capacity</label>
                                    <input type="text" class="form-control" name="capacity" onChange={onCapacityChange} value={capacity} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>InitialInspection</label>
                                    <input type="date" class="form-control" name="initialInspection" onChange={onInitialInspectionChange} value= {initialInspection} />
                         
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>Approval</label>
                                    <input type="text" class="form-control" name="approval" onChange={onApprovalChange} value={approval} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>Material</label>
                                    <input type="text" class="form-control" name="material" onChange={onMaterialChange} value={material} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>TankWorkingPressure</label>
                                    <input type="text" class="form-control" name="tankWorkingPressure" onChange={onTankWorkingPressureChange} value={tankWorkingPressure} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>TankExternalPressure</label>
                                    <input type="text" class="form-control" name="tankExternalPressure" onChange={onTankExternalPressureChange} value={tankExternalPressure} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>ManufacturingThickness</label>
                                    <input type="text" class="form-control" name="manufacturingThickness" onChange={onManufacturingThicknessChange} value={manufacturingThickness} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>TankOperatingTemp</label>
                                    <input type="text" class="form-control" name="tankOperatingTemp" onChange={onTankOperatingTempChange} value={tankOperatingTemp} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>Discharge</label>
                                    <input type="text" class="form-control" name="discharge" onChange={onDischargeChange} value={discharge} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>Heater</label>
                                    <input type="text" class="form-control" name="heater" onChange={onHeaterChange} value={heater} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>HeaterWorkingPressure</label>
                                    <input type="text" class="form-control" name="heaterWorkingPressure" onChange={onHeaterWorkingPressureChange} value={heaterWorkingPressure} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>SteamValves</label>
                                    <input type="text" class="form-control" name="steamValves" onChange={onSteamValvesChange} value={steamValves} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group input-group-static my-3">
                                    <label>MetallurgicalDesignTemp</label>
                                    <input type="text" class="form-control" name="metallurgicalDesignTemp" onChange={onMetallurgicalDesignTempChange} value={metallurgicalDesignTemp} />
                                </div>
                            </div>


                            {messages.length > 0 &&
                                <Alert messages={messages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={cancel}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={addEdit}>
                                    Save
                                </button>
                                { !isEdit &&
                                <button type="button" class="btn btn-primary m-0 ms-2" onClick={addAndGoToNewReport}>
                                    Save & Add new report
                                </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >

    )

}

export default EquipmentCsc_Add;