// src/utils/errorHandler.js
export const handleErrorResponse = (error, setErrorMessages) => {
    if (error.response) {
        const status = error.response.status;

        if (status === 400) {
            if (typeof error.response.data === 'string') {
                setErrorMessages([error.response.data]);
            } else if (typeof error.response.data === 'object') {
                const modelStateErrors = [];
                for (const key in error.response.data) {
                    // Model State
                    if (typeof error.response.data[key] === 'string') {
                        modelStateErrors.push(error.response.data[key]);
                    } else if (typeof error.response.data[key] === 'object') {
                        modelStateErrors.push(error.response.data[key].errorMessage);
                    }
                }
                setErrorMessages(modelStateErrors);
            }
        } else if (status === 500 && typeof error.response.data === 'object') {
            const serverError = error.response.data;
            setErrorMessages([serverError.error, serverError.details]);
        } else {
            setErrorMessages([`An unexpected error occurred. Status: ${status}`]);
        }
    } else {
        setErrorMessages(['Unable to connect to the server.']);
    }
};
