import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Config from '../../Constants/index';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import TextInputField from '../../components/common/TextInputField';
import DateInputField from '../../components/common/DateInputField';
import { Redirect } from 'react-router-dom'
import Alert from '../../Component/Alert'
import { handleErrorResponse } from '../../utils/errorHandler'
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer';
import DueDateOfCalibration from './DueDateOfCalibration';


const AddEntityForm = (props) => {

    /*Begin******************/

    const PAGE_TITLE = 'New CscIsoTank22T2 Report';
    const ENTITY_NAME = 'CscIsoTank22T2Cert';
    const NEXTSTEP_ENTITY_NAME = '';

    const [entity, setEntity] = useState({
        isoTankNo: '',
    });

    const [customerRef, setCustomerRef] = React.useState(-1);   
    const [vCustomerName, setVCustomerName] = React.useState('');
    const [certificateNumber, setCertificateNumber] = React.useState('');

    const onCustomerBind = (id) => setCustomerRef(id);
       
    const onDueDateOfCalibrationChange = (dueDate) => {
        handleChange('dueDateOfInsp',dueDate)
    }

    const mapEntityData = (data) => {
        setEntity((prevState) => ({
            ...prevState,
            isoTankNo: data.isoTankNo,
            tankType: data.tankType,
            cargoName: data.cargoName,
            specialProvisions: data.specialProvisions,
            approvedForTransport: data.approvedForTransport,
            cscSafetyApproval: data.cscSafetyApproval,
            tankModel: data.tankModel,
            maxWorkingPressure: data.maxWorkingPressure,
            testPressure: data.testPressure,
            steamTubeWorkingPressure: data.steamTubeWorkingPressure,
            steamTubeTestPressure: data.steamTubeTestPressure,
            designTemperature: data.designTemperature,
            tankMaterial: data.tankMaterial,
            tankThickness: data.tankThickness,
            maxGrossWeight: data.maxGrossWeight,
            tare: data.tare,
            capacity: data.capacity,
            ownerCodeSerialNo: data.ownerCodeSerialNo,
            manufacturerCodeSerialNo: data.manufacturerCodeSerialNo,
            designStandard: data.designStandard,
            manufacturer: data.manufacturer,
            dischargeTop: data.dischargeTop,
            dischargeBottom: data.dischargeBottom,
            heater: data.heater,
            steamValves: data.steamValves,
            dateManufactured: data.dateManufactured,
            intialInspection: data.intialInspection,
            lastHydraulicTestDate: data.lastHydraulicTestDate,
            lastLeakProofnessTestDate: data.lastLeakProofnessTestDate
        }));
    };

    /*end******************/

    const [id, setId] = React.useState(props.match.params.id);
    const [preStepEntityRef, setPreStepEntityRef] = React.useState(props.match.params.preStepEntityRef);
    debugger;
    const isEdit = props.match.params.id > 0;
    const [isRedirectToGrid, setIsRedirectToGrid] = React.useState(false)
    const [isRedirectToNextStep, setIsRedirectToNextStep] = React.useState(false)
    const [errorMessages, setErrorMessages] = useState([]);



    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);


    useEffect(() => {
        if (isEdit) {
            fetchEntity();
        }
    }, []);

    useEffect(() => {
 
        handleChange('EquipmentCscIsoTank22T2Id',preStepEntityRef);
    }, [preStepEntityRef]);



    const fetchEntity = async () => {
        try {
            const idToken = await getIdToken(user); // Get the token inside the method
            const response = await axios.get(
                `${Config.API_URL}${ENTITY_NAME}/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                    },
                }
            );
            mapEntityData(response.data);
        } catch (error) {
            handleErrorResponse(['Failed to load data for editing. Please try again.'], setErrorMessages);
        }
    };


    const addEntity = async (onSuccess) => {
        try {
            const idToken = await getIdToken(user);

            const response = await axios.post(
                `${Config.API_URL}${ENTITY_NAME}`,
                entity,
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }
            );

            setId(response.data.id); // Update the ID after adding the entity
            setErrorMessages([]);
            if (onSuccess) onSuccess(response.data.id);
        } catch (error) {
            handleErrorResponse(error, setErrorMessages);
        }
    };

    const updateEntity = async (onSuccess) => {
        try {
            const idToken = await getIdToken(user); // Get the token inside the method
            const response = await axios.put(
                `${Config.API_URL}${ENTITY_NAME}/${id}`,
                entity,
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                    },
                }
            );
            setErrorMessages([]);
            if (onSuccess) onSuccess(response.data.id);
        } catch (error) {
            handleErrorResponse(error, setErrorMessages);
        }
    };

    const handleSave = () => {
        const onSuccess = () => setIsRedirectToGrid(true);
        if (isEdit) {
            updateEntity(onSuccess);
        } else {
            addEntity(onSuccess);
        }
    };

    const handleSaveAndNext = () => {
        if (isEdit) {
            const onSuccess = () => { return; }
            updateEntity(onSuccess);
        } else {
            const onSuccess = () => setIsRedirectToNextStep(true);
            addEntity(onSuccess);
        }
    };

    const cancel = () => {
        setIsRedirectToGrid(true);
    }


    if (isRedirectToGrid)
        return <Redirect to={"/" + ENTITY_NAME + "/"} />

    if (isRedirectToNextStep)
        return <Redirect to={"/" + NEXTSTEP_ENTITY_NAME + "/Add/&PreStepEntityRef=" + id} />

    const handleChange = (field, value) => {
        setEntity({ ...entity, [field]: value });
    };



    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-6 col-12 mx-auto position-relative">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fa fa-wrench fa-fw" aria-hidden="true"></i>
                            </div>
                            {preStepEntityRef} sdddd
                            {!isEdit &&
                                <h6 className="mb-0">New {PAGE_TITLE} </h6>
                            }
                            {isEdit &&
                                <>
                                    <h6 className="mb-0">Edit {PAGE_TITLE}</h6>
                                    <p className="mb-0 font-weight-normal text-sm">
                                        {certificateNumber}
                                    </p>
                                </>
                            }
                        </div>


                        <div className="card-body pt-2">



                            {/* Begin ****************** */}
                            <div class="row">
                                <div className="form-group">
                                    <label>
                                        Customer Name
                                    </label>

                                    {!isEdit &&
                                        <UserDefaultCustomer onBind={onCustomerBind} name="CustomerRef" user={user} />
                                    }
                                    {isEdit &&
                                        <p className="form-text text-muted ms-1">
                                            {vCustomerName}
                                        </p>
                                    }
                                </div>
                            </div>
                          
                            <DateInputField
                                label={"Date Of Issue"}
                                value={entity.dateOfIssue}
                                onChange={(value) => handleChange("dateOfIssue", value)}
                            />


                            {isEdit &&
                                <DateInputField
                                label={"Date Of Inspection"}
                                value={entity.dueDateOfInsp}
                                onChange={(value) => handleChange("dueDateOfInsp", value)}
                                />
                            }
                            
                            {!isEdit &&
                            <DueDateOfCalibration dateOfCalibration={entity.dateOfIssue}
                                name="dueDateOfInsp" onDueDateOfCalibrationChange={onDueDateOfCalibrationChange}
                                value={entity.dueDateOfInsp} />
                            } 


                            {/* End ****************** */}

                            {errorMessages.length > 0 &&
                                <Alert messages={errorMessages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={cancel}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={handleSave}>
                                    Save
                                </button>
                                {((!isEdit && NEXTSTEP_ENTITY_NAME)) &&
                                    <button type="button" class="btn btn-primary m-0 ms-2" onClick={handleSaveAndNext}>
                                        Save & Next Step
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >
    );
};

export default AddEntityForm;
